import {} from '../components/safeFrame';
/**
 * @desc Contains safeframe client APIs that are common to desktop and mobile safeframe.
 */
import { producePlayingTimeInfo, produceViewableInfo, update, updatePlayingTime } from '../components/sfAPI';
import { LOADED_HOST_SCOPE, LatencyMetricType } from '../components/metrics/latency-metric-type';
import type { ExtendedUpdateViewabilityData, InternalSFClientAPI, LogInvocation } from './InternalSFClientAPI';
import type { ResizeArgs, SFClientAPI, SFClientVideoAPI } from '@amzn/safe-frame-client-amzn';
import { isVideo } from '../components/util';
import { ClientMessageSender } from './clientMessageSender';
import type { AdDetails } from '../../@types/adCommon';
import { ClientReporter } from './clientReporter';
import { ClientMessageListeners } from './clientMessageReceiver';
import type { ClientApis } from './types/types';
import type { ComputedAdDetailsData, PixelOptionsData } from '../components/commands';
import type { CommonSupportedCommands } from '../host/CommonSupportedCommands';
import type { MobileSupportedCommands } from '../host/MobileSupportedCommands';
import type { DesktopSupportedCommands } from '../host/DesktopSupportedCommands';
import { getScope } from '../components/sparkle';
import { sendCriticalFeatureAndLoaded } from './commonSf';

export abstract class CommonApiImplementation implements SFClientVideoAPI, SFClientAPI, InternalSFClientAPI {
    public readonly cr: ClientReporter;
    constructor(
        protected readonly cms: ClientMessageSender,
        protected readonly o: AdDetails,
        public readonly mL: ClientMessageListeners,
        protected readonly renderFallbackExperience: () => void,
    ) {
        this.cr = new ClientReporter(this as unknown as ClientApis, o, cms);
    }

    /**
     * Given a height and width, increases the size of the placement to fit that aspect ratio using all available screen width. Pushes neighboring content down.
     */
    expandAdSlot = (size: ResizeArgs) => {
        this.cms.sendMessage<CommonSupportedCommands['expandAdSlot']>('expandAdSlot', size);
    };
    /**
     * Given a height and width, increases the size of the safeframe to fit that aspect ratio using all available screen width.
     * This function does not increase the size of the container divs, causing the ad to overlap neighboring content.
     */
    expandAdSlotWithOverlap = (size: ResizeArgs) => {
        this.cms.sendMessage<CommonSupportedCommands['expandAdSlotWithOverlap']>('expandAdSlotWithOverlap', size);
    };
    /**
     * Changes the size of the safeframe and the container div to the default slot size.
     */
    resetSlotSize = () => {
        this.cms.sendMessage<CommonSupportedCommands['resetSlotSize']>('resetSlotSize');
    };

    fireImpression = async (data: PixelOptionsData) => {
        this.sendLatencyMetric(LOADED_HOST_SCOPE);
        await this.cr.fireImpressionPixel(data.measurementMethod, data.isNoInventory);
        // TODO: https://taskei.amazon.dev/tasks/e0684d56-d5ef-4be0-9d6d-14203b3eba78 clean up COD fallback
        this.cms.sendMessage<CommonSupportedCommands['clearCODFallback']>('clearCODFallback');
    };

    sendAdBarTrace = (field: string, traceInfo: any) => {
        this.cms.sendMessage<CommonSupportedCommands['sendAdBarTrace']>('sendAdBarTrace', {
            field: field,
            traceInfo: traceInfo,
        });
    };

    changeSize = (width: string, height: string) => {
        this.cms.sendMessage<CommonSupportedCommands['changeSize']>('changeSize', { width: width, height: height });
    };
    logCsaEvent = (metricName: string) => {
        this.cms.sendMessage<CommonSupportedCommands['logCsaEvent']>('logCsaEvent', { metricName: metricName });
    };
    addCsaEntity = (entity: string) => {
        // TODO This might be a bug/doesn't go anywhere?
        this.cms.sendMessage('addEntity', entity);
    };
    registerCustomMessageListener = (msgKey: string, callback: any) => {
        this.mL[msgKey] = callback;
    };
    sendLatencyMetric = (latencyMetricType: LatencyMetricType) => {
        this.cms.sendMessage<CommonSupportedCommands['sendLatencyMetric']>('sendLatencyMetric', {
            latencyMetricType: latencyMetricType,
            timestamp: new Date(),
        });
    };
    countMetric = (msg: string, num: number, isGlobal?: boolean) => {
        this.cms.sendMessage<CommonSupportedCommands['countMetric']>('countMetric', {
            metricMsg: msg,
            value: num,
            isGlobal: isGlobal ? true : false,
        });
    };
    addCsmTag = (tag: string, msg?: string, isGlobal?: boolean) => {
        this.cms.sendMessage<CommonSupportedCommands['addCsmTag']>('addCsmTag', {
            tag: tag,
            msg: msg,
            isGlobal: isGlobal,
        });
    };
    fireViewableLatencyMetrics = () => {
        this.cms.sendMessage<CommonSupportedCommands['fireViewableLatencyMetrics']>('fireViewableLatencyMetrics', {
            adLoadedTimestamp: Date.now(),
        });
    };
    /**
     * @desc Used by Creative template owners to trigger metric for 'Sponsored Label' rendered.
     */
    feedbackLabelRendered = () => {
        this.cms.sendMessage<CommonSupportedCommands['feedbackLabelRendered']>('feedbackLabelRendered');
    };
    /**
     * @desc Used by Creative template owners to open feedback Modal.
     */
    feedbackOpenModal = () => {
        this.cms.sendMessage<CommonSupportedCommands['feedbackOpenModal']>('feedbackOpenModal');
    };
    customMessage = (msgKey: string, messageBody: object | string) => {
        this.cms.sendMessage<CommonSupportedCommands['customMessage']>('customMessage', {
            key: msgKey,
            body: messageBody,
        });
    };

    updateViewability = (data: ExtendedUpdateViewabilityData) => {
        const clientSfViewableInfo = produceViewableInfo();
        // update cached viewability for synchronous viewability access
        update(data);
        if (!this.o.aaxInstrPixelUrl || !clientSfViewableInfo.payload || !clientSfViewableInfo.viewabilityStandards) {
            return;
        }
        if (!this.o.isNoInventory && data.atf !== undefined) {
            this.cr.fireMeasurabilityPixel(data);
        }
        const geom = window?.$sf?.ext?.geom && window?.$sf?.ext.geom();
        const cachedViewability = geom?.self?.iv ? geom?.self?.iv : null;
        if (isVideo(this.o.mediaType)) {
            const clientSfPlayingTimeInfo = producePlayingTimeInfo();
            this.cr.fireViewablePixelsForVideo(
                cachedViewability,
                clientSfViewableInfo.payload(),
                clientSfViewableInfo.viewabilityStandards(),
                this.o.isNoInventory ? true : false,
                clientSfPlayingTimeInfo.playingTime ? clientSfPlayingTimeInfo.playingTime() : null,
            );
        } else {
            this.cr.fireViewablePixels(
                cachedViewability,
                clientSfViewableInfo.payload(),
                clientSfViewableInfo.viewabilityStandards(),
                this.o.isNoInventory ? true : false,
            );
        }
    };

    /**
     * Viewability pixels for video are fired from a combination of time spent in the view port and time spent playing.
     * For ad templates which implement their own video player (ie Sponsored Display's PERC), they need to tell SF how much
     * time has been spent playing. SF will combine this with its knowledge of in view time to determine when to fire a
     * viewability pixel.
     *
     * @param continuousPlaybackSeconds - This is the maximum amount of continuous playback time the videoplayer has observed (in seconds)
     */
    updatePlayingTimeForVideo = (continuousPlaybackSeconds: number) => {
        const clientSfViewableInfo = produceViewableInfo();
        updatePlayingTime(continuousPlaybackSeconds);
        const geom = window?.$sf?.ext?.geom && window.$sf.ext.geom();
        const cachedViewability = geom?.self?.iv;
        if (cachedViewability && clientSfViewableInfo.payload && clientSfViewableInfo.viewabilityStandards) {
            const clientSfPlayingTimeInfo = producePlayingTimeInfo();
            this.cr.fireViewablePixelsForVideo(
                cachedViewability,
                clientSfViewableInfo.payload(),
                clientSfViewableInfo.viewabilityStandards(),
                this.o.isNoInventory ? true : false,
                clientSfPlayingTimeInfo.playingTime ? clientSfPlayingTimeInfo.playingTime() : null,
            );
        }
    };
    requestVideoAutoplay = () => {
        this.cms.sendMessage<CommonSupportedCommands['requestVideoAutoplay']>('requestVideoAutoplay');
    };
    releaseVideoAutoplay = () => {
        this.cms.sendMessage<CommonSupportedCommands['releaseVideoAutoplay']>('releaseVideoAutoplay');
    };
    haveVideoAutoplayPermission = () => {
        this.cms.sendMessage<CommonSupportedCommands['haveVideoAutoplayPermission']>('haveVideoAutoplayPermission');
    };

    isOnAmazon = (): boolean => {
        return true;
    };
    /**
     * @desc Tells the host script to collapse the slot (ie display:none)
     */
    collapseSlotInternal = () => {
        this.logAPIInvocation('collapseSlot');
        this.cms.sendMessage<MobileSupportedCommands['collapseSlot'] | DesktopSupportedCommands['collapseSlot']>(
            'collapseSlot',
        );
    };
    logError = (message: string, error?: Error, level?: number) => {
        this.cms.sendMessage<CommonSupportedCommands['logError']>('logError', { message, error, level });
    };

    collapseSlot = () => {
        this.handleFallbackBehavior();
    };

    /**
     * @desc Renders fallback experience for the ad slot, to preserve CX when ad cannot be rendered properly.
     *
     * This is invoked either when there is an ad punt, or if there is an error in writing creative to the iframe, or if there is an ad blocker detected.
     */
    handleFallbackBehavior = () => {
        this.logAPIInvocation('handleFallbackBehavior');
        this.renderFallbackExperience();
    };

    /**
     * @desc Currently used to remove AdFeedback label and link.
     *
     * This is invoked as part of the fallback behavior (see CommonApiImplementation.handleFallbackBehavior for triggers).
     * Used for fallback behavior requiring calls to host.
     */
    handleFallbackExperience = () => {
        this.cms.sendMessage<CommonSupportedCommands['handleFallbackExperience']>('handleFallbackExperience');
    };

    resizeCreativeWrapper = (data: { height: string; width: string }) => {
        const creativeWrapper = window.document.getElementById(`ape_${getScope(this.o)}_creativeWrapper`);
        if (creativeWrapper === null) {
            return;
        }

        creativeWrapper.style.height = data.height;
        creativeWrapper.style.width = data.width;
    };

    /**
     * Used to emit a custom event to the ad notifying that an event occurred outside the safeframe.
     * @param data object containing the name of the event which is being propagated to the ad
     */
    triggerEventInIframe = (data: { eventType: string }) => {
        window.dispatchEvent(new Event('outerpage' + data.eventType));
    };

    logAPIInvocation: LogInvocation = (apiName: string, ...apiParams: any[]) => {
        const API = 'messenger_';
        this.cms.sendMessage<CommonSupportedCommands['logAPIInvocation']>('logAPIInvocation', {
            apiName: apiName,
            apiParams: apiParams,
        });
        this.cms.sendMessage<CommonSupportedCommands['logCsaEvent']>('logCsaEvent', { metricName: API + apiName });
    };

    setupComputedAdDetails = (data: ComputedAdDetailsData) => {
        this.o.computed = data.computed;
    };

    signalAdLoadedAndInteractive = () => {
        sendCriticalFeatureAndLoaded(this);
    };
}
